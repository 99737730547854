import request from '@/api/request'

export function listPolygonalFence (data) {
  const req = {
    deviceImei: data.deviceImei
  }
  return request({
    url: 'polygonalFence/list',
    method: 'post',
    data: req
  })
}

export function insertPolygonalFence (data) {
  const req = {
    deviceImei: data.deviceImei,
    fenceName: data.fenceName,
    inOut: data.inOut,
    pointList: data.pointList
  }
  return request({
    url: 'polygonalFence/insertPolygonalFence',
    method: 'post',
    data: req
  })
}

export function deletePolygonalFence (data) {
  const req = {
    fenceId: data.fenceId
  }
  return request({
    url: 'polygonalFence/deletePolygonalFence',
    method: 'post',
    data: req
  })
}
