<template>
  <div class="main">
  <el-container>
    <el-aside width="240px">
      <br/>
      <el-form :model="addFenceForm"
               ref="addFenceFormRef"
               :rules="addFenceFormRules"
               label-width="100px">
        <el-form-item :label="$t('polygonalFence.fenceName')" >
          <el-input v-model="addFenceForm.fenceName" class="addFenceColumn"></el-input>
        </el-form-item>
        <el-form-item :label="$t('polygonalFence.inOut')" prop="inOut">
          <el-select v-model="addFenceForm.inOut"
                     size="small"
                     class="addFenceColumn">
            <el-option value="2" label="For Out"></el-option>
            <el-option value="1" label="For In"></el-option>
            <el-option value="3" label="For In/Out"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="8" :offset="1">
        <el-button type="text" @click="openSearch">{{$t('polygonalFence.query')}} </el-button>
        </el-col>
        <el-col :span="8" :offset="6">
        <el-button type="text" @click="addPolygonalFence">{{$t('polygonalFence.addFence')}}</el-button>
        </el-col>
      </el-row>
      <el-table :data="polygonalFenceList" border stripe>
        <el-table-column prop="fenceId" label="Fence Id" v-if="false"></el-table-column>
        <el-table-column prop="fenceName" :label="$t('polygonalFence.fenceName')" width="100px"></el-table-column>
        <el-table-column prop="inOut" :label="$t('polygonalFence.inOut')" :formatter="inOutFormatter" width="60px"></el-table-column>
        <el-table-column :label="$t('common.operation')" width="78px">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            @click="deleteFence(scope.row.fenceId)"
          ></el-button>
        </template>
        </el-table-column>
      </el-table>
    </el-aside>
    <div v-if="searchDialogVisible" class="searchClass">
      <el-card>
        <el-form :model="locatePoint"
                 ref="locatePointRef"
                 label-width="90px">
          <el-form-item :label="$t('polygonalFence.latitude')"  >
            <el-input type="number" v-model="locatePoint.lat"></el-input>
          </el-form-item>
          <el-form-item :label="$t('polygonalFence.longitude')" >
            <el-input type="number" v-model="locatePoint.lng" ></el-input>
          </el-form-item>
        </el-form>
        <el-button type="warning" @click="searchDialogVisible = false" >{{$t('common.close')}}</el-button>
        <el-button type="primary" @click="locate" >{{$t('common.search')}}</el-button>
      </el-card>
    </div>
    <el-main>
      <div class="circularFence">
        <GmapMap
          ref="mapRef"
          map-type-id="terrain"
          :center="{lat:25.037798, lng:121.565170}"
          v-if="currentMap === 'google'"
          :zoom="13"
          style="width: 100%; height: 100%"
          :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUI: false
            }"></GmapMap>
          <baidu-map v-if="currentMap === 'baidu'" id="baiduMap" @ready="handleBaidu" style="width: 100%; height: 100%"
                   :scroll-wheel-zoom='true'>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"/>
          <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"/>
        </baidu-map>
      </div>
    </el-main>
  </el-container>
  </div>
</template>

<script>
import { getGpsDeviceByImei } from '@/api/gpsDevice'
import { checkLogin } from '@/api/globalProcessErr'
import { listPolygonalFence, deletePolygonalFence, insertPolygonalFence } from '@/api/polygonalFence'

import { initMap, moveToCenter, makeMarker, makePath, cleanAllPath, cleanCurrentPath } from '@/api/map'
import { deviceStatusFormat, getObjString } from '@/api/util'

export default {
  name: 'circularFence',
  data () {
    return {
      deviceImei: '',
      center: {
        lat: 22.5711440000,
        lng: 113.8687300000
      },
      polygonalFenceList: [],
      clickedPoint: {
        lat: undefined,
        lng: undefined
      },
      device: undefined,
      mapInstance: undefined,
      devicePath: [],
      prePath: undefined,
      currentMap: 'baidu',
      currPath: undefined,
      deviceMark: {
        deviceImei: '',
        marker: undefined,
        infoWindow: undefined
      },
      addDialogVisible: false,
      addFenceForm: {
        fenceName: '',
        inOut: undefined
      },
      pointList: [],
      locatePoint: {
        lat: undefined,
        lng: undefined
      },
      searchDialogVisible: false,
      createPoly: false,
      fenceList: []
    }
  },
  computed: {
    addFenceFormRules () {
      return {
        fenceName: [
          { required: true, message: this.$t('circularFence.rules.fenceName.required'), trigger: 'blur' }
        ],
        inOut: [
          { required: true, message: this.$t('circularFence.rules.inOut.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.deviceImei = window.sessionStorage.getItem('polygonalFenceDeviceImei') || ''
    this.checkLang()
    // this.getGpsDevice()
    // this.getPolygonalFenceList()
  },
  mounted () {
    const _this = this
    setTimeout(function () {
      _this.initMapPage()
      makeMarker(_this.mapInstance, _this.device)
      _this.drawPolygon()
    }, 600)
  },
  methods: {
    checkLang () {
      if (this.$i18n.locale === 'zh-CN') {
        this.currentMap = 'baidu'
      } else {
        this.currentMap = 'google'
      }
    },
    handleBaidu ({ BMap, map }) {
      this.mapInstance = {
        mapType: 'baidu',
        map: map,
        bMap: BMap,
        // map: window.$BAIDU$._instances.TANGRAM__1,
        // bMap: window.BMap,
        markList: [],
        currentOpenInfoWindow: undefined,
        contentFunc: this.makeContent,
        currentPoly: undefined,
        polyList: [],
        haveBtn: false,
        currentPoint: undefined,
        circleList: [],
        circleMarkList: [],
        clickFunc: this.clickFunc
      }
    },
    initMapPage () {
      if (this.currentMap === 'google') {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'google',
            map: this.$refs.mapRef.$mapObject,
            mapRef: this.$refs.mapRef,
            googleMaps: window.google.maps,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false,
            currentPoint: undefined,
            circleList: [],
            circleMarkList: [],
            clickFunc: this.clickFunc
          }
        }
      } else {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'baidu',
            // map: map,
            // bMap: BMap,
            map: window.$BAIDU$._instances.TANGRAM__1,
            bMap: window.BMap,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false,
            currentPoint: undefined,
            circleList: [],
            circleMarkList: [],
            clickFunc: this.clickFunc
          }
        }
      }
      initMap(this.mapInstance, this.center, 13)
    },
    makeContent (data, haveBtn) {
      const self = this
      // const motionStatus = self.motionStatusFormatter(data)
      return function () {
        let item = 'engine'
        let content = '<style>'
        content = content + '.deviceInfo{ padding: 0 0 0 0; margin: 0 0 0 0; .columnName{ width: 25%; } .columnValue{ width: 25%; }}'
        content = content + '</style>'
        content = content + '<div class="deviceInfo"><table frame="void">'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.deviceImei') + '</b> : </td>'
        content = content + '<td class="columnValue">' + data.deviceImei + '</td>'
        content = content + '<td class="columnName"><b> ' + self.$t('device.deviceName') + '</b> : </td>'
        content = content + '<td class="columnValue">' + data.deviceName + '</td>'
        content = content + '</tr>'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.lastLatitude') + '</b> : </td>'
        content = content + '<td class="columnValue">' + getObjString(data.lastLatitude) + '</td>'
        content = content + '<td class="columnName"><b> ' + self.$t('device.lastLongitude') + '</b> ：</td>'
        content = content + '<td class="columnValue">' + getObjString(data.lastLongitude) + '</td>'
        content = content + '</tr>'
        content = content + '</tr>'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.accStatus') + '</b> : </td>'
        content = content + '<td class="columnValue">' + deviceStatusFormat(data, item) + '</td>'
        content = content + '<td class="columnName" ><b> ' + self.$t('device.lastLocationTime') + '</b> ：</td>'
        content = content + '<td class="columnValue" style="width: 160px;">' + getObjString(data.lastLocationTime) + '</td>'
        content = content + '</tr>'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.lastSpeed') + '</b> : </td>'
        content = content + '<td class="columnValue">' + getObjString(data.lastSpeed) + '</td>'
        content = content + '<td class="columnName" ><b> ' + self.$t('device.heading') + '</b> ：</td>'
        content = content + '<td class="columnValue" >' + getObjString(data.heading) + '</td>'
        content = content + '</tr>'

        if (haveBtn) {
          content = content + '<tr><td> <button onclick="realTimeTrack(\'' + data.deviceImei + '\')" style="width: 150px;" type="button"> Real time tracking </button> </td>'
          content = content + '<td> <button onclick="historicalTrack(\'' + data.deviceImei + '\')" style="width: 150px; type="button"> Historical track </button> </td>'
          content = content + '<td> <button onclick="sendCommand(\'' + data.deviceImei + '\')" style="width: 150px; type="button"> Send Command </button></td>'
          content = content + '<td> <button onclick="getDeviceInfo(\'' + data.deviceImei + '\')" style="width: 150px; type="button"> Device Infomation</button> </td></tr>'
        }

        content += '</table></div>'

        return content
      }
    },
    motionStatusFormatter (row, column) {
      if (row.lastMotionStatus === 1) {
        return 'motion'
      } else if (row.lastMotionStatus === 0) {
        return 'static'
      } else {
        return ''
      }
    },
    openSearch () {
      this.searchDialogVisible = !this.searchDialogVisible
    },
    addPolygonalFence () {
      if (this.pointList.length < 3) {
        return this.$message.error('Please select at least 3 points on the map')
      }
      const self = this
      this.$refs.addFenceFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return

        const req = {
          deviceImei: self.deviceImei,
          fenceName: self.addFenceForm.fenceName,
          inOut: self.addFenceForm.inOut,
          pointList: self.pointList
        }
        insertPolygonalFence(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          self.$message.info('create success!')
          self.addDialogVisible = false
          self.pointList = []
          self.getPolygonalFenceList()
          setTimeout(function () {
            self.drawPolygon()
          }, 600)
          self.$refs.addFenceFormRef.resetFields()
          self.addFenceForm.fenceName = ''
          self.addFenceForm.inOut = undefined
        }).catch(err => {
          return self.$message.error(err.message)
        })
      })
    },
    inOutFormatter (row, column) {
      if (row.inOut === 1) {
        return 'For In'
      } else {
        return 'For Out'
      }
    },
    deleteFence (fenceId) {
      const req = {
        fenceId: fenceId
      }
      const self = this
      deletePolygonalFence(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        self.$message.info('delete success!')
        self.getPolygonalFenceList()
        setTimeout(function () {
          self.drawPolygon()
        }, 600)
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getPolygonalFenceList () {
      const req = {
        deviceImei: this.deviceImei
      }
      const self = this
      listPolygonalFence(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.polygonalFenceList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getGpsDevice () {
      getGpsDeviceByImei(this.deviceImei).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.device = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    locate () {
      const self = this
      if (this.locatePoint.lat === undefined || this.locatePoint.lng === undefined) {
        return this.$message.error(self.$t('circularFence.rules.latLng'))
      }
      const center = {
        lat: Number(this.locatePoint.lat),
        lng: Number(this.locatePoint.lng)
      }
      if (center.lat > 90 || center.lat < -90) {
        return this.$message.warning(self.$t('circularFence.rules.lat'))
      }
      if (center.lng > 180 || center.lng < -180) {
        return this.$message.warning(self.$t('circularFence.rules.lng'))
      }
      moveToCenter(self.mapInstance, center)
    },
    clickFunc (e) {
      if (e !== undefined) {
        const point = {
          lng: e.lng,
          lat: e.lat
        }
        this.pointList.push(point)
        this.makeLine(this.pointList, true)
      }
    },
    makeLine (pointList, isCreate) {
      if (pointList.length <= 2) return
      let points = []
      for (let j = 0; j < pointList.length; j++) {
        points.push(pointList[j])
      }
      points.push(points[0])
      if (isCreate) {
        if (this.createPoly) {
          cleanCurrentPath(this.mapInstance)
        }
      }
      makePath(this.mapInstance, points)
      if (isCreate) {
        this.createPoly = true
      }
    },
    drawPolygon () {
      cleanAllPath(this.mapInstance)
      for (let i = 0; i < this.polygonalFenceList.length; i++) {
        let pointList = []
        for (let j = 0; j < this.polygonalFenceList[i].list.length; j++) {
          let data = {
            lng: this.polygonalFenceList[i].list[j].longitude,
            lat: this.polygonalFenceList[i].list[j].latitude
          }
          pointList.push(data)
        }
        this.makeLine(pointList, false)
      }
      this.createPoly = false
    }
  }
}
</script>

<style  scoped>

.el-aside {
  height: 100% !important;
  position: relative;
}

.el-main {
  height: 100%;
  width: calc(100% - 260px) !important;
  margin: 0 0 0 0;
  position: relative;
}

.circularFence {
  width: 100% !important;
  height: 900px !important;
  margin: 0 0 0 0;
  position: relative;

}

.searchClass {
  position: absolute;
  width: 230px;
  margin: 0 auto;
  left: 35%;
  z-index: 10;
  bottom: 30px;
}

.addFenceColumn {
  width: 130px;
}

</style>
