// import fa from 'element-ui/src/locale/lang/fa'

import moment from 'moment'

export function getObjString (data) {
  if (data === undefined || data === null || data === '') {
    return ''
  }
  return data
}

export function deviceStatusFormat (device, item) {
  if (device === undefined) {
    return ''
  }
  let status
  switch (item) {
    case 'engine':
      if (objIsNotNull(device.status) && objIsNotNull(device.status.engineStatus)) {
        status = device.status.engineStatus
      }
      break
    case 'airCondition':
      if (objIsNotNull(device.status) && objIsNotNull(device.status.airConditionStatus)) {
        status = device.status.airConditionStatus
      }
      break
    case 'door':
      if (objIsNotNull(device.status) && objIsNotNull(device.status.doorStatus)) {
        status = device.status.doorStatus
      }
      break
    case 'headlight':
      if (objIsNotNull(device.status) && objIsNotNull(device.status.headlightStatus)) {
        status = device.status.headlightStatus
      }
      break
    case 'movement':
      if (objIsNotNull(device.status) && objIsNotNull(device.status.movementStatus)) {
        status = device.status.movementStatus
      }
      break
    default:
  }
  if (status === undefined) {
    return ''
  } else if (status === 1) {
    return 'ON'
  } else {
    return 'OFF'
  }
}

export function objIsNull (obj) {
  if (obj === undefined || obj === null || obj === '') {
    return true
  } else {
    return false
  }
}

export function objIsNotNull (obj) {
  return !objIsNull(obj)
}

/**
 * 将时间戳转换为字符串
 * @param {Date} date 日期
 * @param {String} formatStr 自定义时间格式，默认'yyyy/MM/DD HH:mm:ss'
 * @returns DateString
 */
export function convertDateTimeToString (date, formatStr) {
  if (formatStr) {
    return moment(date).format(formatStr)
  } else {
    return moment(date).format('yyyy/MM/DD HH:mm:ss')
  }
}
